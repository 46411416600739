import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import cx from 'classnames';

// eslint-disable-next-line arrow-body-style
const Section = forwardRef(({ title, children, className, ...props }, ref) => {
  return (
    <section ref={ref} {...props} className={cx('wrapper', className)}>
      <div className="title">{title}</div>
      <div className="container">
        {children}
      </div>
    </section>
  );
});

Section.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default Section;
