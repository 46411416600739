import PropTypes from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

import defaultImage from '~/images/banner.jpg';

export default function SEO({ lang, title, description, image, path }) {
  const { site: { siteMetadata: site } } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            domain
            title
            tagline
            description
            author
            fbid
          }
        }
      }
    `,
  );

  return (
    <Helmet
      htmlAttributes={{ lang }}
      title={title ? `${title} | ${site.title} - ${site.tagline}` : `${site.title} - ${site.tagline}`}
      meta={[
        {
          name: 'description',
          content: description || site.description,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'og:title',
          content: title ? `${title} | ${site.title} - ${site.tagline}` : `${site.title} - ${site.tagline}`,
        },
        {
          property: 'og:description',
          content: description || site.description,
        },
        {
          property: 'og:image',
          content: `${site.domain}${image || defaultImage}`,
        },
        path && {
          property: 'og:url',
          content: `${site.domain}${path.replace(/^\/pens-by-yetti/, '').replace(/\/$/, '')}`,
        },
        {
          property: 'fb:app_id',
          content: site.fbid,
        },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        {
          name: 'twitter:creator',
          content: site.author,
        },
        {
          name: 'twitter:title',
          content: title,
        },
        {
          name: 'twitter:description',
          content: description || site.description,
        },
      ].filter(v => v)}
    />
  );
}

SEO.defaultProps = {
  lang: 'en',
};

SEO.propTypes = {
  lang: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  path: PropTypes.string,
};
