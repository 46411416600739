import PropTypes from 'prop-types';

function airtable(shape) {
  return PropTypes.shape({
    nodes: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      data: PropTypes.shape(shape).isRequired,
    })),
  });
}

function airtableAttachments({ thumbnails } = {}) {
  const shape = {
    url: PropTypes.string.isRequired,
  };

  if (thumbnails) {
    shape.thumbnails = {};
    for (const type of thumbnails) {
      shape.thumbnails[type] = PropTypes.shape({
        url: PropTypes.string.isRequired,
      }).isRequired;
    }

    shape.thumbnails = PropTypes.shape(shape.thumbnails).isRequired;
  }

  return PropTypes.arrayOf(PropTypes.shape(shape));
}

function airtableRemark({ exerpt } = {}) {
  const shape = {
    html: PropTypes.string.isRequired,
  };

  if (exerpt === true) {
    shape.exerpt = PropTypes.string.isRequired;
  }

  return PropTypes.shape({
    childMarkdownRemark: PropTypes.shape(shape).isRequired,
  });
}

function transformAirtable({ nodes }) {
  const types = {};
  for (const { data } of nodes) {
    for (const prop in data) {
      if (isAttachments(data[prop])) {
        types[prop] = 'attachments';
        continue;
      }

      if (isRemark(data[prop])) {
        types[prop] = 'remark';
        continue;
      }

      if (Array.isArray(data[prop])) {
        types[prop] = 'list';
        continue;
      }

      if (data[prop] !== null && data[prop] !== undefined) {
        types[prop] = typeof data[prop];
        continue;
      }
    }
  }

  return nodes.map(({ id, data: input }) => {
    const output = { id };

    for (const prop in input) {
      if (types[prop] === 'attachments') {
        const alts = (input[`${prop}Alt`] || '').split('\n').map(alt => alt || undefined);
        output[prop] = input[prop].map(({ url, thumbnails }, index) => ({
          url,
          alt: alts.length === 1 ? alts[0] : alts[index],
          thumbnails,
        }));
        continue;
      }

      if (types[prop] === 'remark') {
        output[prop] = input[prop].childMarkdownRemark;
        continue;
      }

      if (types[prop] === 'boolean') {
        output[prop] = !!input[prop];
        continue;
      }

      if ([ 'number', 'string', 'list' ].includes(types[prop])) {
        output[prop] = input[prop];
        continue;
      }

      throw new Error(`Unrecognized type at ${prop}`);
    }

    return output;
  });
}

export default {
  airtable,
  airtableAttachments,
  airtableRemark,
  transformAirtable,
};

function isAttachments(value) {
  if (!Array.isArray(value)) {
    return false;
  }

  if (!value.every(({ url }) => typeof url === 'string')) {
    return false;
  }

  return true;
}

function isRemark(value) {
  if (!value || typeof value !== 'object') {
    return false;
  }

  const { childMarkdownRemark, ...rest } = value;

  if (Object.keys(rest).length > 0) {
    return false;
  }

  if (typeof childMarkdownRemark !== 'object') {
    return false;
  }

  return true;
}
