import { useMemo, useState } from 'react';

export default function useFilters(items, cols) {
  const [ filters, setFilters ] = useState({});

  const filtered = useMemo(() =>
    getFiltered(items, cols, filters),
  [ cols, filters, items ]);

  const states = useMemo(() =>
    cols.map(col => [
      filters[col] === undefined ? '' : filters[col],
      (value) => setFilters({ ...filters, [col]: value === '' ? undefined : value }),
    ]),
  [ cols, filters ]);

  const options = useMemo(() =>
    getOptions(items, cols),
  [ cols, items ]);

  return [
    filtered,
    states,
    options,
  ];
}

function getOptions(list, cols) {
  const filters = [];
  for (const index in cols) {
    filters[index] = [];
  }

  for (const item of list) {
    for (const index in cols) {
      const col = cols[index];

      if (Array.isArray(item[col])) {
        for (const value of item[col]) {
          if (value === null || value === undefined) {
            continue;
          }

          if (!filters[index].includes(value)) {
            filters[index].push(value);
          }
        }
      }
      else {
        const value = item[col];
        if (value === null || value === undefined) {
          continue;
        }

        if (!filters[index].includes(value)) {
          filters[index].push(value);
        }
      }
    }
  }

  for (const index in filters) {
    filters[index].sort((a, b) => {
      if (typeof a === 'boolean' && typeof b === 'boolean') {
        return b > a ? 1 : -1;
      }

      return a > b ? 1 : -1;
    });
  }

  return filters;
}

function getFiltered(list, cols, filter) {
  return list.filter(item => {
    for (const col of cols) {
      if (filter[col] === '' || filter[col] === undefined) {
        continue;
      }

      if (Array.isArray(filter[col]) && Array.isArray(item[col])) {
        if (!filter[col].every(v => item[col].includes(v))) {
          return false;
        }
        continue;
      }

      if (Array.isArray(filter[col])) {
        if (!filter[col].includes(item[col])) {
          return false;
        }
        continue;
      }

      if (Array.isArray(item[col])) {
        if (!item[col].includes(filter[col])) {
          return false;
        }
        continue;
      }

      if (item[col] !== filter[col]) {
        return false;
      }
    }

    return true;
  });
}
