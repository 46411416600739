import { useEffect, useState } from 'react';

export function useInitialAnimations() {
  setTimeout(() => {
    if (typeof document !== 'undefined') {
      document.body.classList.remove('is-preload');
    }
  }, 100);
}

export function useNavVisible() {
  const [ isNavVisible, setNavVisible ] = useState(false);

  useEffect(() => {
    function handleClick() {
      setNavVisible(false);
    }

    function handleKeyDown(event) {
      if (event.keyCode === 27) {
        setNavVisible(false);
      }
    }

    document.body.addEventListener('click', handleClick);
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      document.body.removeEventListener('click', handleClick);
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return [ isNavVisible, setNavVisible ];
}
