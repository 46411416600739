import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';

import SEO from './components/seo';
import { useInitialAnimations, useNavVisible } from './hooks';

import './sass/layout.scss';

/**
 * Escape Velocity by HTML5 UP
 * https://html5up.net/escape-velocity
 * html5up.net | @ajlkn
 * Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
*/

// eslint-disable-next-line arrow-body-style
const Layout = forwardRef(({ type, children, ...props }, ref) => {
  useInitialAnimations();

  const [ isNavVisible, setNavVisible ] = useNavVisible();

  const { site: { siteMetadata: { title, tagline } } } = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          tagline
        }
      }
    }
  `);

  return (
    <div ref={ref} id="page-wrapper" className={`${type} fade-in ${isNavVisible ? 'is-navPanel-visible' : ''}`}>
      <SEO {...props} />

      <section id="header" className="wrapper">
        <div id="logo">
          <h1><Link to="/">{title}</Link></h1>
          <p>{tagline}</p>
        </div>
        <nav id="nav">
          <ul>
            <li className="current"><Link to="/">Home</Link></li>
            <li><Link to="/pens">Handmade Pens</Link></li>
            {/* <li><Link to="/custom">Custom Projects</Link></li> */}
            <li><Link to="/refills">Inks and Refills</Link></li>
            <li><Link to="/#contact">Contact</Link></li>
          </ul>
        </nav>
      </section>

      {children}

      <section id="copyright">
        <ul>
          <li>&copy; {title}.</li>
          <li>Design: <a href="https://html5up.net/escape-velocity">HTML5 UP</a></li>
        </ul>
      </section>

      <div id="titleBar">
        <button
          type="button"
          className="toggle fas fa-bars"
          tabIndex={0}
          onClick={() => setNavVisible(!isNavVisible)}
        >
          <span>Open Navigation</span>
        </button>
        <span className="title">{title}</span>
      </div>
      <div id="navPanel">
        <nav>
          <Link to="/" className="link depth-0">
            <span className="indent-0" />
            Home
          </Link>
          <Link to="/pens" className="link depth-0">
            <span className="indent-0" />
            Handmade Pens
          </Link>
          {/* <Link to="/custom" className="link depth-0">
            <span className="indent-0" />
            Custom Projects
          </Link> */}
          <Link to="/refills" className="link depth-0">
            <span className="indent-0" />
            Inks and Refills
          </Link>
          <Link to="/#contact" className="link depth-0">
            <span className="indent-0" />
            Contact
          </Link>
        </nav>
      </div>
    </div>
  );
});

Layout.defaultProps = {
  type: 'no-sidebar',
};

Layout.propTypes = {
  type: PropTypes.oneOf([ 'homepage', 'no-sidebar', 'left-sidebar', 'right-sidebar' ]).isRequired,
  children: PropTypes.node.isRequired,
};

export default Layout;
