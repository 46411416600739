import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import cx from 'classnames';

// eslint-disable-next-line arrow-body-style
const Header = forwardRef(({ title, children, className, ...props }, ref) => {
  return (
    <header ref={ref} {...props} className={cx(className)}>
      <h2>{title}</h2>
      {isText(children) ? (<p>{children}</p>) : children}
    </header>
  );
});

Header.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

function isText(children) {
  return React.Children
    .map(children, child => typeof child === 'string')
    .filter(v => v)
    .length > 0;
}

export default Header;
